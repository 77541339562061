<template>
  <div class="row">
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
      <a-card>
        <div class="row">
          <div class="col-xs-7 col-sm-7 col-md-7 col-lg-7">
            <strong>Form Analisa Slik</strong>
          </div>
          <div class="col-xs-5 col-sm-5 col-md-5 col-lg-5">
            <a-button class="btn btn-outline-danger float-right" @click="back"
              >Return</a-button
            >
          </div>
          <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6 mt-3">
            <div class="row">
              <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-1">
                <span>No Register</span>
              </div>
              <div class="col-xs-7 col-sm-7 col-md-7 col-lg-7">
                <a-select
                  v-if="pinginubah"
                  show-search
                  label-in-value
                  id="inputdatapemohonnomor_registrasi"
                  v-model="input.datapemohon.nomor_registrasi"
                  placeholder="Nomor registrasi pemohon"
                  style="width: 100%;"
                  :filter-option="false"
                  :not-found-content="fetching ? undefined : null"
                  @search="fetchUser"
                  @change="selectRegister"
                >
                  <a-spin v-if="fetching" slot="notFoundContent" size="small" />
                  <a-select-option v-for="d in data" :key="d.kode">
                    {{ d.kode + "-" + d.nama }}
                  </a-select-option>
                </a-select>
                <a-input
                  :readOnly="true"
                  :value="input.datapemohon.nomor_registrasi.key"
                  @click="
                    dataedit === null
                      ? (pinginubah = false)
                      : (pinginubah = true)
                  "
                  v-else
                ></a-input>
                <!-- <a-select
                  show-search
                  placeholder="Select No Register"
                  option-filter-prop="children"
                  style="width: 100%;"
                  :filter-option="filterOption"
                >
                  <a-select-option value="test">
                    test
                  </a-select-option>
                </a-select> -->
              </div>
              <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-2">
                <span>Nama</span>
              </div>
              <div class="col-xs-7 col-sm-7 col-md-7 col-lg-7 mt-1">
                <a-input
                  class=""
                  :readOnly="true"
                  v-model="datanasabah.nama"
                  placeholder="Nama"
                />
              </div>
              <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-2">
                <span>Alamat</span>
              </div>
              <div class="col-xs-7 col-sm-7 col-md-7 col-lg-7 mt-1">
                <a-input
                  class=""
                  :readOnly="true"
                  v-model="datanasabah.alamat"
                  placeholder="Alamat"
                />
              </div>
            </div>
          </div>
          <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6 mt-3">
            <div class="row">
              <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-1">
                <span>Tanggal</span>
              </div>
              <div class="col-xs-7 col-sm-7 col-md-7 col-lg-7">
                <a-date-picker
                  @change="onChange"
                  format="DD-MM-YYYY"
                  style="width: 200px;"
                  v-model="input.tgl"
                />
              </div>
            </div>
          </div>
          <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 mt-3">
            <strong class="mb-1"
              >Data {{ datanasabah === null ? "" : datanasabah.nama }}</strong
            >
            <a-button
              class="btn btn-outline-primary ml-2 mb-1"
              :disabled="editingKey !== ''"
              @click="tambahData('datatable', 'datatablebackup')"
            >
              <a-icon type="plus" />
            </a-button>
            <a-table
              :columns="columns"
              :data-source="datatable"
              size="small"
              :loading="loading"
              :pagination="{
                hideOnSinglePage: true,
                defaultPageSize: 10,
                showQuickJumper: true,
                showSizeChanger: true,
                showTotal: (total) => `Total ${total} items`,
                pageSizeOptions: ['10', '20', '30'],
              }"
              :key="componentKey"
            >
              <span slot="no" slot-scope="text, record, index">
                {{ index + 1 }}
              </span>
              <template
                v-for="col in editablecolumn"
                :slot="col.name"
                slot-scope="text, record, index"
              >
                <div :key="col.name">
                  <cleave
                    :raw="true"
                    :options="options.number"
                    class="ant-input"
                    v-if="record.editable && col.type === 'number'"
                    :placeholder="'Input ' + col.name"
                    v-model="datatable[index][col.name]"
                    @keypress.enter="() => save(record.key, 'datatable')"
                    @keydown.esc="
                      () => cancel(record.key, 'datatable', 'datatablebackup')
                    "
                  />
                  <a-input
                    v-if="record.editable && col.type === 'string'"
                    style="margin: -5px 0;"
                    :placeholder="'Input ' + col.name"
                    :value="text"
                    v-model="datatable[index][col.name]"
                    @keypress.enter="() => save(record.key, 'datatable')"
                    @keydown.esc="
                      () => cancel(record.key, 'datatable', 'datatablebackup')
                    "
                  />
                  <template v-else>
                    <span v-if="!record.editable">{{ text }}</span>
                  </template>
                </div>
              </template>
              <template slot="operation" slot-scope="text, record, index">
                <div class="editable-row-operations">
                  <span v-if="record.editable">
                    <a @click="() => save(record.key, 'datatable')">
                      <a-icon type="check" class="text-success" />
                    </a>
                    <a-divider type="vertical" />
                    <a-popconfirm
                      title="Sure to cancel?"
                      @confirm="
                        () => cancel(record.key, 'datatable', 'datatablebackup')
                      "
                    >
                      <a-icon type="close" class="text-danger" />
                    </a-popconfirm>
                  </span>
                  <span v-else>
                    <a
                      :disabled="editingKey !== ''"
                      @click="
                        () => edit(record.key, 'datatable', 'datatablebackup')
                      "
                    >
                      <a-icon type="edit" class="text-warning" />
                    </a>
                    <a-divider type="vertical" />
                    <a
                      :disabled="editingKey !== ''"
                      @click="() => removeItem(index, 'datatable')"
                    >
                      <a-icon type="delete" class="text-danger" />
                    </a>
                  </span>
                </div>
              </template>
              <template slot="footer" slot-scope="currentPageData">
                <strong>Total BakiDebet: </strong>
                {{ checkTotal(currentPageData) }}
              </template>
            </a-table>
          </div>
          <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 mt-3">
            <strong class="mb-1">Atas nama Suami / Istri</strong>
            <a-button
              class="btn btn-outline-primary ml-2 mb-1"
              :disabled="editingKey !== ''"
              @click="tambahData('datatable1', 'datatablebackup1')"
            >
              <a-icon type="plus" />
            </a-button>
            <a-table
              :columns="columns"
              :data-source="datatable1"
              size="small"
              :loading="loading"
              :pagination="{
                hideOnSinglePage: true,
                defaultPageSize: 10,
                showQuickJumper: true,
                showSizeChanger: true,
                showTotal: (total) => `Total ${total} items`,
                pageSizeOptions: ['10', '20', '30'],
              }"
              :key="componentKey"
            >
              <span slot="no" slot-scope="text, record, index">
                {{ index + 1 }}
              </span>
              <template
                v-for="col in editablecolumn"
                :slot="col.name"
                slot-scope="text, record, index"
              >
                <div :key="col.name">
                  <cleave
                  :raw="true"
                  :options="options.number"
                  class="ant-input"
                    v-if="record.editable && col.type === 'number'"
                    :placeholder="'Input ' + col.name"
                    v-model="datatable1[index][col.name]"
                    @keypress.enter="() => save(record.key, 'datatable1')"
                    @keydown.esc="
                      () => cancel(record.key, 'datatable1', 'datatablebackup1')
                    "
                  />
                  <a-input
                    v-if="record.editable && col.type === 'string'"
                    style="margin: -5px 0;"
                    :placeholder="'Input ' + col.name"
                    v-model="datatable1[index][col.name]"
                    @keypress.enter="() => save(record.key, 'datatable1')"
                    @keydown.esc="
                      () => cancel(record.key, 'datatable1', 'datatablebackup1')
                    "
                  />
                  <template v-else>
                    <span v-if="!record.editable">{{ text }}</span>
                  </template>
                </div>
              </template>
              <template slot="operation" slot-scope="text, record, index">
                <div class="editable-row-operations">
                  <span v-if="record.editable">
                    <a @click="() => save(record.key, 'datatable1')">
                      <a-icon type="check" class="text-success" />
                    </a>
                    <a-divider type="vertical" />
                    <a-popconfirm
                      title="Sure to cancel?"
                      @confirm="
                        () =>
                          cancel(record.key, 'datatable1', 'datatablebackup1')
                      "
                    >
                      <a-icon type="close" class="text-danger" />
                    </a-popconfirm>
                  </span>
                  <span v-else>
                    <a
                      :disabled="editingKey !== ''"
                      @click="
                        () => edit(record.key, 'datatable1', 'datatablebackup1')
                      "
                    >
                      <a-icon type="edit" class="text-warning" />
                    </a>
                    <a-divider type="vertical" />
                    <a
                      :disabled="editingKey !== ''"
                      @click="() => removeItem(index, 'datatable1')"
                    >
                      <a-icon type="delete" class="text-danger" />
                    </a>
                  </span>
                </div>
              </template>
              <template slot="footer" slot-scope="currentPageData">
                <strong>Total BakiDebet: </strong>
                {{ checkTotal(currentPageData) }}
              </template>
            </a-table>
          </div>
          <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6"></div>
          <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6">
            <a-button
              class="btn btn-outline-primary float-right mt-2"
              style="width: 12em;"
              @click="submitData"
              >Submit</a-button
            >
            <strong class="mr-3 mt-3 float-right">Final Skor: {{ total_skor }}</strong>
          </div>
        </div>
      </a-card>
    </div>
  </div>
</template>

<script>
import * as lou from '@/services/data/lou'
import debounce from 'lodash/debounce'
import moment from 'moment'
import Cleave from 'vue-cleave-component'
export default {
  components: {
    cleave: Cleave,
  },
  created() {
    console.log('this.dataedit', this.dataedit)
    if (this.dataedit.id === '') {
      this.action = 'create'
      if (this.datanasabahformlain !== null) {
        this.datanasabah = this.datanasabahformlain
        this.input.datapemohon.nomor_registrasi.key = this.datanasabahformlain.kode
      }
    } else {
      this.action = 'update'
      this.datanasabah = this.dataedit.datanasabah
      this.input.datapemohon.nomor_registrasi.key = this.dataedit.kode_pengajuan
      this.input.tgl = moment(this.dataedit.tgl, 'YYYY-MM-DD')
      for (let i = 0; i < this.dataedit.details.length; i++) {
        const element = this.dataedit.details[i]
        var uuid = this.$uuid.v4().replace(/[^a-z\d\s]+/gi, '')
        element.key = uuid
        if (element.jenis === '0') {
          this.datatable.push(element)
          this.datatablebackup.push(element)
        } else {
          this.datatable1.push(element)
          this.datatablebackup1.push(element)
        }
      }
    }
  },
  data() {
    this.lastFetchId = 0
    this.fetchUser = debounce(this.fetchUser, 800)
    return {
      action: 'create',
      pinginubah: false,
      data: [],
      datanasabah: { nama: '', alamat: '', tglregister: '' },
      dataedit: JSON.parse(localStorage.getItem('dataeditanalisaslik')),
      datanasabahformlain: JSON.parse(localStorage.getItem('datanasabahformlain')),
      componentKey: 0,
      total_skor: 0,
      input: {
        datapemohon: {
          nomor_registrasi: {
            key: '',
          },
        },
        tgl: moment(),
      },
      options: {
        number: {
          numeral: true,
        },
      },
      editablecolumn: [
        {
          name: 'bank',
          type: 'string',
        },
        {
          name: 'bakidebet',
          type: 'number',
        },
        {
          name: 'realisasi',
          type: 'number',
        },
        {
          name: 'kol',
          type: 'number',
        },
        {
          name: 'keterangan',
          type: 'string',
        },
      ],
      editingKey: '',
      datatablebackup: [],
      datatablebackup1: [],
      datatable: [],
      datatable1: [],
      columns: [
        {
          title: 'No',
          // dataIndex: '',
          width: '5%',
          scopedSlots: { customRender: 'no' },
        },
        {
          title: 'BANK',
          dataIndex: 'bank',
          width: '18%',
          scopedSlots: { customRender: 'bank' },
        },
        {
          title: 'BAKIDEBET',
          dataIndex: 'bakidebet',
          width: '15%',
          scopedSlots: { customRender: 'bakidebet' },
        },
        {
          title: 'REALISASI',
          dataIndex: 'realisasi',
          width: '15%',
          scopedSlots: { customRender: 'realisasi' },
        },
        {
          title: 'KOL',
          dataIndex: 'kol',
          width: '5%',
          scopedSlots: { customRender: 'kol' },
        },
        {
          title: 'KETERANGAN',
          dataIndex: 'keterangan',
          width: '25%',
          scopedSlots: { customRender: 'keterangan' },
        },
        {
          title: '#',
          dataIndex: 'operation',
          width: '7%',
          scopedSlots: { customRender: 'operation' },
        },
      ],
      loading: false,
      skortemplate: [
        {
          first: 1.01,
          second: 1.25,
          score: 9,
        },
        {
          first: 1.26,
          second: 1.5,
          score: 8,
        },
        {
          first: 1.51,
          second: 1.75,
          score: 7,
        },
        {
          first: 1.76,
          second: 2,
          score: 6,
        },
        {
          first: 2.01,
          second: 2.25,
          score: 5,
        },
        {
          first: 2.26,
          second: 2.5,
          score: 4,
        },
        {
          first: 2.51,
          second: 2.75,
          score: 3,
        },
        {
          first: 2.76,
          second: 3,
          score: 2,
        },
        {
          first: 3.01,
          second: 100000,
          score: 1,
        },
      ],
    }
  },
  methods: {
    moment,
    checkSkor() {
      var tempskor = 0
      var temprata = 0
      if (this.datatable.length !== 0) {
        this.datatable.forEach(x => {
          if (x.keterangan === 'Lancar') {
            tempskor += 1
          } else if (x.keterangan === 'DPK') {
            tempskor += 2
          } else if (x.keterangan === 'Kurang Lancar') {
            tempskor += 3
          } else if (x.keterangan === 'Diragukan') {
            tempskor += 4
          }
        })
      }
      if (this.datatable1.length !== 0) {
        this.datatable1.forEach(x => {
          if (x.keterangan === 'Lancar') {
            tempskor += 1
          } else if (x.keterangan === 'DPK') {
            tempskor += 2
          } else if (x.keterangan === 'Kurang Lancar') {
            tempskor += 3
          } else if (x.keterangan === 'Diragukan') {
            tempskor += 4
          }
        })
      }
      var totaldata = this.datatable.length + this.datatable1.length
      temprata = tempskor / totaldata
      // console.log('totaldata', totaldata)
      // console.log('tempskor', tempskor)
      // console.log('temprata', temprata)
      this.skortemplate.forEach(x => {
        if (temprata >= x.first && temprata <= x.second) {
          this.total_skor = x.score
        }
      })
      // console.log('this.total_skor', this.total_skor)
    },
    checkTotal(currentPageData) {
      // console.log('currentPageData', currentPageData)
      var total = 0
      for (let i = 0; i < currentPageData.length; i++) {
        const el = currentPageData[i]
        // console.log('el', el)
        var bakidebet = el.bakidebet.toString().replace(/\D/g, '')
        total += parseFloat(bakidebet)
      }
      this.checkSkor()
      return currentPageData.length === 0 ? '0' : lou.curency(total)
    },
    async submitData() {
      var fd = {
        kode_pengajuan: this.input.datapemohon.nomor_registrasi.key,
        tgl: this.input.tgl.format('YYYY-MM-DD'),
        skor: this.total_skor,
        details: [],
      }
      for (let i = 0; i < this.datatable.length; i++) {
        const element = this.datatable[i]
        element.jenis = '0'
        if (element.editable !== undefined) {
          delete element.editable
        }
        if (element.key !== undefined) {
          delete element.key
        }
        fd.details.push(element)
      }
      for (let i = 0; i < this.datatable1.length; i++) {
        const element = this.datatable1[i]
        element.jenis = '1'
        if (element.editable !== undefined) {
          delete element.editable
        }
        if (element.key !== undefined) {
          delete element.key
        }
        fd.details.push(element)
      }
      var res = ''
      if (this.action === 'create') {
        res = await lou.customUrlPost2('analisaslik', fd)
      } else {
        fd.id = this.dataedit.id
        res = await lou.customUrlPut2('analisaslik', fd)
      }
      if (res) {
        this.getExcel(res.data)
        if (this.datanasabahformlain !== null) {
          this.$router.go(-2)
          localStorage.removeItem('datanasabahformlain')
        } else {
          this.back()
        }
      }
    },
    async getExcel(record) {
      // await lou.getDocumentExcel('analisaslik', id)
      const ExcelJS = require('exceljs')
      // const uuid = require('uuid').v4()
      const newWorkbook = new ExcelJS.Workbook()
      newWorkbook.creator = 'Taksasi System By Heasoft'
      newWorkbook.lastModifiedBy = 'Taksasi System By Heasoft'
      newWorkbook.created = new Date()
      newWorkbook.modified = new Date()
      newWorkbook.lastPrinted = new Date()
      newWorkbook.calcProperties.fullCalcOnLoad = true
      newWorkbook.addWorksheet('Analisa Slik')
      const worksheet = newWorkbook.getWorksheet('Analisa Slik')
      worksheet.mergeCells('A1:G1')
      worksheet.mergeCells('A2:B2')
      worksheet.getCell('A1').value = 'HASIL SLIK'
      worksheet.getCell('A2').value = 'BANK'
      worksheet.getCell('C2').value = 'KOL'
      worksheet.getCell('D2').value = 'BAKI DEBET'
      worksheet.getCell('E2').value = 'REALISASI'
      worksheet.getCell('F2').value = 'KETERANGAN'
      worksheet.getCell('G2').value = 'PUNYA'
      worksheet.getCell('A1').alignment = { vertical: 'middle', horizontal: 'center' }
      worksheet.getCell('A2').alignment = { vertical: 'middle', horizontal: 'center' }
      worksheet.getCell('C2').alignment = { vertical: 'middle', horizontal: 'center' }
      worksheet.getCell('D2').alignment = { vertical: 'middle', horizontal: 'center' }
      worksheet.getCell('E2').alignment = { vertical: 'middle', horizontal: 'center' }
      worksheet.getCell('F2').alignment = { vertical: 'middle', horizontal: 'center' }
      worksheet.getCell('G2').alignment = { vertical: 'middle', horizontal: 'center' }
      worksheet.getCell('A1').font = {
        name: 'Times New Roman',
        size: 10,
        bold: true,
      }
      worksheet.getCell('A2').font = {
        name: 'Times New Roman',
        size: 10,
        bold: true,
      }
      worksheet.getCell('C2').font = {
        name: 'Times New Roman',
        size: 10,
        bold: true,
      }
      worksheet.getCell('D2').font = {
        name: 'Times New Roman',
        size: 10,
        bold: true,
      }
      worksheet.getCell('E2').font = {
        name: 'Times New Roman',
        size: 10,
        bold: true,
      }
      worksheet.getCell('F2').font = {
        name: 'Times New Roman',
        size: 10,
        bold: true,
      }
      worksheet.getCell('G2').font = {
        name: 'Times New Roman',
        size: 10,
        bold: true,
      }
      worksheet.getCell('A1').border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' },
      }
      worksheet.getCell('A2').border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' },
      }
      worksheet.getCell('C2').border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' },
      }
      worksheet.getCell('D2').border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' },
      }
      worksheet.getCell('E2').border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' },
      }
      worksheet.getCell('F2').border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' },
      }
      worksheet.getCell('G2').border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' },
      }
      var startrow = 3
      var totalbakidebet = 0
      var totalrealisasi = 0
      for (let i = 0; i < record.details.length; i++) {
        const element = record.details[i]
        worksheet.mergeCells(`A${startrow}:B${startrow}`)
        worksheet.getCell(`A${startrow}`).border = {
          top: { style: 'thin' },
          left: { style: 'thin' },
          bottom: { style: 'thin' },
          right: { style: 'thin' },
        }
        worksheet.getCell(`C${startrow}`).border = {
          top: { style: 'thin' },
          left: { style: 'thin' },
          bottom: { style: 'thin' },
          right: { style: 'thin' },
        }
        worksheet.getCell(`D${startrow}`).border = {
          top: { style: 'thin' },
          left: { style: 'thin' },
          bottom: { style: 'thin' },
          right: { style: 'thin' },
        }
        worksheet.getCell(`E${startrow}`).border = {
          top: { style: 'thin' },
          left: { style: 'thin' },
          bottom: { style: 'thin' },
          right: { style: 'thin' },
        }
        worksheet.getCell(`F${startrow}`).border = {
          top: { style: 'thin' },
          left: { style: 'thin' },
          bottom: { style: 'thin' },
          right: { style: 'thin' },
        }
        worksheet.getCell(`G${startrow}`).border = {
          top: { style: 'thin' },
          left: { style: 'thin' },
          bottom: { style: 'thin' },
          right: { style: 'thin' },
        }
        worksheet.getCell(`A${startrow}`).value = element.bank
        worksheet.getCell(`C${startrow}`).value = element.kol
        worksheet.getCell(`D${startrow}`).value = lou.curency(element.bakidebet)
        worksheet.getCell(`E${startrow}`).value = lou.curency(element.realisasi)
        worksheet.getCell(`F${startrow}`).value = element.keterangan
        worksheet.getCell(`G${startrow}`).value = element.jenis === '0' ? 'Pemohon' : 'Pasangan Pemohon'
        totalbakidebet += parseFloat(element.bakidebet)
        totalrealisasi += parseFloat(element.realisasi)
        startrow += 1
      }

      worksheet.mergeCells(`A${startrow}:B${startrow}`)
      worksheet.getCell(`A${startrow}`).border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' },
      }
      worksheet.getCell(`C${startrow}`).border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' },
      }
      worksheet.getCell(`D${startrow}`).border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' },
      }
      worksheet.getCell(`E${startrow}`).border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' },
      }
      worksheet.getCell(`F${startrow}`).border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' },
      }
      worksheet.getCell(`G${startrow}`).border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' },
      }
      startrow += 1
      worksheet.getCell(`A${startrow}`).border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' },
      }
      worksheet.getCell(`C${startrow}`).border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' },
      }
      worksheet.getCell(`D${startrow}`).border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' },
      }
      worksheet.getCell(`E${startrow}`).border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' },
      }
      worksheet.getCell(`F${startrow}`).border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' },
      }
      worksheet.getCell(`G${startrow}`).border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' },
      }
      worksheet.mergeCells(`A${startrow}:B${startrow}`)
      worksheet.getCell(`A${startrow}`).value = 'Jumlah'
      worksheet.getCell(`D${startrow}`).value = lou.curency(totalbakidebet)
      worksheet.getCell(`E${startrow}`).value = lou.curency(totalrealisasi)
      const buffer = await newWorkbook.xlsx.writeBuffer()
      var blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
      var link = document.createElement('a')
      link.href = window.URL.createObjectURL(blob)
      // var form = this.whatform.slice(7, this.whatform.length)
      link.download = 'Cetak slik.xlsx'
      link.click()
    },
    selectRegister(value) {
      var backup = this.$g.clone(this.data)
      var target = backup.find((x) => x.kode === value.key)
      if (target) {
        this.datanasabah = target
        console.log('this.datanasabah', this.datanasabah)
      }
      Object.assign(this, {
        value,
        data: [],
        fetching: false,
      })
    },
    async fetchUser(value) {
      // console.log('fetchinguser', value)
      if (value !== '') {
        this.lastFetchId += 1
        this.data = []
        this.fetching = true
        var response = await lou.customUrlGet2('pengajuankredit?limit=10&kode=' + value)
        if (response) {
          if (response.data.length !== 0) {
            this.data = response.data
          } else {
            this.data = []
            lou.shownotif('Not Found!', 'Data not found!')
            this.fetching = false
          }
        }
      } else {
        console.log('Empty: -')
      }
    },
    pilihPemohon(value) {
      this.value = value
      fetch(value, (data) => (this.data = data))
      // console.log('value', value)
    },
    onChange(date, dateString) {
      // console.log(date, dateString)
      // console.log('this.datanasabah.tglregister', this.datanasabah.tglregister.format('YYYY-MM-DD'))
    },
    removeItem(key, source) {
      this[source].splice(key, 1)
    },
    tambahData(source, to) {
      this[to] = this.$g.clone(this[source])
      // console.log('this[source]', this[source])
      // console.log('this[to]', this[to])
      var uuid = this.$uuid.v4().replace(/[^a-z\d\s]+/gi, '')
      var fd = {
        key: uuid,
        bank: '',
        bakidebet: '',
        realisasi: '',
        kol: '',
        keterangan: '',
        editable: true,
      }
      this.editingKey = uuid
      this[source].push(fd)
      this.componentKey += 1
    },
    handleChange(value, key, column, source) {
      console.log('value', value)
      const target = this[source].filter((item) => key === item.key)[0]
      if (target) {
        target[column] = value
      }
    },
    edit(key, source, to) {
      this[to] = this.$g.clone(this[source])
      const target = this[source].filter((item) => key === item.key)[0]
      this.editingKey = key
      // console.log('target', target)
      // console.log('this.datatablebackup', this.datatablebackup)
      if (target) {
        target.editable = true
        this.componentKey += 1
        // var ent = key + 'inputinlinecol' + this.editablecolumn[0]
        // console.log('ent', ent)
        // setTimeout(() => {
        //   this.$refs[ent].$el.focus()
        // }, 500)
      }
    },
    save(key, source) {
      console.log('key', key)
      console.log('source', source)
      const target = this[source].filter((item) => key === item.key)[0]
      console.log('target', target)
      if (target) {
        delete target.editable
        // console.log('this.datatable[target]', this.datatable[target])
        this.editingKey = ''
        this.componentKey += 1
      }
    },
    cancel(key, source, from) {
      // console.log('key', key)
      // console.log('source', source)
      // console.log('from', from)
      // const targetBackup = this[from].findIndex(
      //   (item) => key === item.key,
      // )
      const target = this[source].findIndex((item) => key === item.key)
      // console.log('target', target)
      if (target >= 0) {
        delete this[source][target].editable
        // console.log('this[from][targetBackup]', this[from][targetBackup])
        this[source] = this[from]
        // console.log('this[from][target]', this[from][target])
        this.editingKey = ''
        this.componentKey += 1
      }
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      )
    },
    back() {
      this.$router.go(-1)
    },
  },
}
</script>

<style></style>
